export const MemberComment = [
  {
    name: '大森 真利子',
    position: '三菱地所住宅業務企画部\n新事業創造部',
    text: '文鳥・ウサギ・猫・犬、カメ・金魚・エビ。子供のころから生き物がいつもそばにいました。癒しと活力をくれるペットは私にとってかけがえのない存在です。一方で心配事もたえず、ペットのために生活を変えようかという思いがよぎることも。\n多様性が広がる中、何かのために別の何かを諦めるのではなく、ペットとの生活も従来型に留まらない例えば拡大家族のような選択肢があってもいいのでは？それぞれの状況にあった生活環境によってペットと飼い主のQOLはもっと高められるのではないか？安心と笑顔を欲張った先にはまた明るいことが待っている！この思いから企画を立ち上げました。\nP-manから新しいライフスタイルとペットフレンドリーを提案していきます！',
    image: 'member01.webp',
  },
  {
    name: '宮脇 優子',
    position: '三菱地所住宅業務企画部',
    text: '子供の頃に、可愛がっていたインコを不注意から死なせてしまったことから生き物を飼う自信を失い、ペットは飼わないと思った時期もありました。\nでも、物心ついた頃からずっと生き物が好きでいつかまた縁を見つけてペットを飼いたいと考えています。P-manプロジェクトは「飼い主の都合でペットに不自由させない」暮らしを考え抜いて、実現を目指して日々奮闘しています。\n生き物が好きな住人とゆるくつながれるコミュニティがあれば、安心してペットと暮らせるように思えました。将来ここに住めるように、という思いを持ちながらこのPJに参加させてもらっています。\nペットと共に豊かに暮らせるペット共生マンション「P-man」、ぜひ応援してください。皆様の参加をお待ちしています。',
    image: 'member02.webp',
  },
  {
    name: '水戸 安奈',
    position: '三菱地所住宅業務企画部',
    text: 'ペットは飼っていませんが、三歳の息子と友人のにゃんこの世話をよく手伝っています。友人は普段とても忙しく、出張が多いです。時々、にゃんこをペットホテルに預けていますが、予約が取れないことも多く、料金も高いといっていました。そのため、長期出張の際には、私と息子が毎日にゃんこの世話をしに行って、ごはんをあげたり、トイレの砂を交換したり、一緒に遊んだりしていて、にゃんこの嬉しそうなの笑顔を見ると、私たちも癒されます。しかし、いつでもお手伝いできるというわけではないので、にゃんこが部屋で飼い主の帰りを待っている寂しそうな姿を思い浮かべると、私と息子はとても心が痛みます。会社内にペット共生マンションの事業化を検討しているチームがあると知って、とても嬉しく思って、一緒にやりたいと手をあげました！この優しい事業に貢献できるように頑張りたいです！',
    image: 'member03.webp',
  },
  {
    name: '糸井 隆恵',
    position: '三菱地所住宅業務企画部',
    text: '「ペットを飼える」ではなく、「ペットと共生する」ような住まい・暮らしを目指します。\n私は保護猫を2匹飼っており、実家でもトイプードルを1匹飼っています。\n以前賃貸マンションに住んでいた時は管理規約の都合上、また1人ではお世話しきれないと考え自分でペットを飼うことは諦め、癒しを求めトイプードルに会うために頻繁に実家へ帰るような生活をしていました。\n今ではペット飼育可能なマンションを選び引っ越したため、気兼ねなく猫との生活を楽しめていますが、ペットを飼えなかった当時の自身の体験から、ペットとの生活を楽しみ人生がより豊かになる人が増え、社会がペットフレンドリーになるようなアイデアを検討しています。\n都内では飼育されているペットの数が人間の子供の数より多いというような話も耳にするようになり、ペットの存在がより家族に近くなってきていると実感しているため、「ペットと共生する」をキーワードに皆さんとぜひ思いを膨らませていきたいです。',
    image: 'member04.webp',
  },
  {
    name: '斉藤 舞',
    position: '三菱地所住宅業務企画部\n（サークル・オブ・ライフ）',
    text: '私は猫を2匹飼っており、今まさにペットフレンドリーなマンションに住んでいます。その良さを実感しているからこそ、この『P-man』を実現させ、より多くの人・ペットにその魅力を伝えたいです。\n私は仕事柄、取材や撮影、イベントなど、現場に出向くことが多く、また、出張も大体月2回以上はあります。出張や旅行中のペットシッターサービスはもちろんありがたいですし、留守中の室内環境や災害時、また日頃のペットの健康に関する不安は常に付きまとうので、そういった時に『P-man』のようなサービスがあると非常に心強いと感じています。\n大手デベロッパーの三菱地所が取り組むことで、「ペットとの共生」が当たり前になる日が来るのでは！という希望を持って取り組んでいます。そして日本全体がもっともっとペットフレンドリー先進国になってほしいと心から願っています。',
    image: 'member05.webp',
  },
];
