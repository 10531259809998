import React from 'react';
/** components **/
import { ContentsTitle } from 'components/molecules';
import { FadeUp } from 'components/organisms';
/** styles,images **/
import s from '../../styles/organisms/our-partners.module.scss';
import JamcLogo from 'assets/images/jamc.webp';
import RoloLogo from 'assets/images/rolo.webp';
import QalLogo from 'assets/images/qal.png';
import LanimalLogo from 'assets/images/lanimal.png';
import PawparkLogo from 'assets/images/pawpark.png';
import IkoLogo from 'assets/images/iko.jpg';
import EvcareLogo from 'assets/images/evcare.svg';
import PetsallrightLogo from 'assets/images/petsallright.png';
export const OurPartner: React.FC = () => {
  return (
    <FadeUp>
      <div className={s.OurPartnerWrapper}>
        <ContentsTitle className={s.contentsTitle} title="OUR PARTNER" subTitle="パートナー企業" />
        <p>本プロジェクトを応援・協力いただいている企業様です。</p>
        <ul>
          <li>
            <a href="https://jamc.co.jp/" target="_blank" rel="noreferrer">
              <img src={JamcLogo} alt="Japan Animal Medical Center" width={200} />
            </a>
          </li>
          <li>
            <a href="https://qalstartups.co.jp/" target="_blank" rel="noreferrer">
              <img src={QalLogo} alt="株式会社QALstartups" width={200} />
            </a>
          </li>
          <li>
            <a href="https://lanimal.co.jp/" target="_blank" rel="noreferrer">
              <img src={LanimalLogo} alt="株式会社ラニマル" width={200} />
            </a>
          </li>
          <li>
            <a href="https://pawpark.jp/" target="_blank" rel="noreferrer">
              <img src={PawparkLogo} alt="pawpark" width={200} />
            </a>
          </li>
          <li>
            <a href="https://r.goope.jp/izuiko2019/" target="_blank" rel="noreferrer">
              <img className={s.limitScale} src={IkoLogo} alt="アイコ" width={100} />
            </a>
          </li>
          <li>
            <a href="https://evcare.co.jp/" target="_blank" rel="noreferrer">
              <img src={EvcareLogo} alt="evcare" width={100} />
            </a>
          </li>
          <li>
            <a href="https://about.petsallright.net/" target="_blank" rel="noreferrer">
              <img src={PetsallrightLogo} alt="petsallright" width={100} />
            </a>
          </li>
          <li>
            <a href="https://rolo.jp/" target="_blank" rel="noreferrer">
              <img src={RoloLogo} alt="rolo" width={200} />
            </a>
          </li>
        </ul>
      </div>
    </FadeUp>
  );
};
