import React, { useRef } from 'react';
/** lib **/
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import classNames from 'classnames';
import { SERVICE_NAME } from '../../const';
import { SupporterVoiceList } from 'lib/supporter-voice';
/** components **/
import { ContentsTitle, LeadContents } from '../molecules';
import { FadeUp, ParallaxItem } from 'components/organisms';
/** styles,images **/
import s from '../../styles/organisms/supporter-voice.module.scss';
import { ReactComponent as BlackCircleArrow } from 'assets/images/black-circle-arrow.svg';
import BgLogo from 'assets/images/bg-logo.svg';

export const SupporterVoice: React.FC = () => {
  const swiperRef = useRef<SwiperCore>();
  const SwiperButtonPrev = (): JSX.Element => {
    return (
      <button onClick={() => swiperRef.current?.slidePrev()}>
        <BlackCircleArrow className={classNames(s.navButton, s.prevButton)} />
      </button>
    );
  };

  const SwiperButtonNext = (): JSX.Element => {
    return (
      <button onClick={() => swiperRef.current?.slideNext()}>
        <BlackCircleArrow className={classNames(s.navButton, s.nextButton)} />
      </button>
    );
  };
  return (
    <div className={s.supporterVoiceWrapper}>
      <FadeUp delay={0}>
        <ContentsTitle
          className={s.contentsTitle}
          title={`SUPPORTER’S\nVOICE`}
          subTitle="ご登録いただいたサポーターの声"
        />
      </FadeUp>
      <FadeUp>
        <div className={s.inner}>
          <Swiper
            className={s.slideWrapper}
            onBeforeInit={(swiper: any) => {
              swiperRef.current = swiper;
            }}
            spaceBetween={24}
            speed={700}
            slidesPerView={1.3}
            loop={true}
            breakpoints={{
              1024: {
                slidesPerView: 2.5,
                spaceBetween: 16,
              },
            }}
          >
            {SupporterVoiceList.map((item, index) => (
              <SwiperSlide className={s.item} key={index}>
                <img src={require(`assets/images/${item.image}`)} alt={item.spec} />
                <div className={s.text}>
                  <p className={s.spec}>{item.spec}</p>
                  <p>{item.text}</p>
                </div>
              </SwiperSlide>
            ))}
            <div className={s.navWrapper}>
              <SwiperButtonPrev />
              <SwiperButtonNext />
            </div>
          </Swiper>
        </div>
      </FadeUp>
      <LeadContents
        className={s.leadContents}
        title="今だけ！Amazonギフト券プレゼント！"
        gaActionName="サポーター登録ボタンクリック(ご登録いただいたサポーターの声)"
      >
        <h3>
          あなたの ”あったらいいな” が現実になるかも？
          <br />
          サポーター登録いただいた⽅の中から、抽選で120名様にAmazonギフト券2,000円分をプレゼント！
        </h3>
        <p>
          応募にはサポーター登録をしていただく必要がございます。サポーター様にはイベントのご案内やいち早く最新情報をお届けします。
        </p>
        <p>※ 提供有無は物件によって異なります</p>
      </LeadContents>
      <ParallaxItem className={s.parallaxBg}>
        <img src={BgLogo} alt={SERVICE_NAME} />
      </ParallaxItem>
    </div>
  );
};
