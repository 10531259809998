import React, { useEffect, useState, useRef } from 'react';
// firebase
import { collection, doc, getDoc, getDocs, increment, setDoc, updateDoc } from 'firebase/firestore';
import { db } from 'api/firebase';

/** plugin, lib **/
import { SERVICE_NAME } from '../../const';
import { allArea, allAreaByType } from 'lib/area';
import classNames from 'classnames';
import { REGISTER_FORM_URL } from 'const';

/** components **/
import { ContentsTitle } from '../molecules';
import { FadeUp, AreaFloorPlanRentSlider, ParallaxItem } from 'components/organisms';

/** styles **/
import s from '../../styles/organisms/area-floor-plan-rent.module.scss';

/** images **/
import { ReactComponent as GreenCircleArrow } from 'assets/images/green-circle-arrow.svg';
import BgLogo from 'assets/images/bg-logo.svg';

export const AreaFloorPlanRent: React.FC = () => {
  const initailType = {
    type: 'リバーサイド',
    slideStartIndex: 0,
  };
  const TYPE_LIST = [
    {
      type: 'リバーサイド',
      slideStartIndex: 0,
    },
    {
      type: '猫フレンドリー',
      slideStartIndex: 2,
    },
    {
      type: '大型公園',
      slideStartIndex: 4,
    },
    {
      type: '都心',
      slideStartIndex: 6,
    },
    {
      type: '都心アクセス',
      slideStartIndex: 8,
    },
  ];
  const [selectedType, setSelectedType] = useState<{ type: string; slideStartIndex: number }>(initailType);

  const [ownLikedIdList, setOwnLikedIdList] = useState<string[]>([]);
  const [likeCountList, setLikeCountList] = useState<any[]>([]);
  const inViewRef1 = useRef<HTMLHeadingElement>(null);
  const inViewRef2 = useRef<HTMLHeadingElement>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const animationEl = [inViewRef1, inViewRef2];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(s.inview);
          } else {
            entry.target.classList.remove(s.inview);
          }
        });
      },
      {
        root: null,
        rootMargin: '-80% 0px -10% 0px',
        threshold: 0,
      },
    );
    animationEl.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      animationEl.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, [animationEl]);

  const votesCollections = collection(db, 'votes');

  const onClickLikeButton = (id: string): void => {
    const serializedVoteNumArray = localStorage.getItem('voteNum');

    if (!serializedVoteNumArray) {
      // localStorageにデータがない場合
      localStorage.setItem('voteNum', JSON.stringify([id]));
      updateLike(id);
      getOwnLikedList();
      return;
    }

    const newVotedNumArray = JSON.parse(serializedVoteNumArray) as any[];
    if (newVotedNumArray.includes(id)) {
      // 変換配列に引数IDが含まれている場合は配列から削除
      newVotedNumArray.filter((a: string) => {
        return a !== id;
      });
      var index = newVotedNumArray.indexOf(id);
      if (index >= 0) {
        newVotedNumArray.splice(index, 1);
      }
      deleteLike(id);
    } else {
      // 変換配列に引数IDが含まれていない場合は追加
      newVotedNumArray.push(id);
      updateLike(id);
    }
    const serializedNewVotedNumArray = JSON.stringify(newVotedNumArray);
    localStorage.setItem('voteNum', serializedNewVotedNumArray as string);
    getOwnLikedList();
  };

  const filteringList = (typeItem: { type: string; slideStartIndex: number }) => {
    setSelectedType(typeItem);
  };

  const getLikeCountData = async () => {
    // firestoreからlikeCountデータ取得
    const snapshot = await getDocs(votesCollections);
    const likeCountData: any[] = snapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    setLikeCountList(likeCountData);
  };

  const getOwnLikedList = () => {
    const serializedVoteNumArray = localStorage.getItem('voteNum');
    if (!serializedVoteNumArray) return;
    const newVotedNumArray = JSON.parse(serializedVoteNumArray) as any[];
    setOwnLikedIdList(newVotedNumArray);
  };

  const updateLike = async (id: string) => {
    ga4EventSend(id, 'like');
    const docRef = doc(db, 'votes', id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      await updateDoc(docRef, {
        likeCount: increment(1),
      })
        .then(() => {
          getLikeCountData();
        })
        .catch((error) => {
          console.error('Error updating document: ', error);
        });
    } else {
      await setDoc(docRef, {
        likeCount: 1,
      })
        .then(() => {
          getLikeCountData();
        })
        .catch((error) => {
          console.error('Error updating document: ', error);
        });
    }
  };
  const deleteLike = async (id: string) => {
    const docRef = doc(db, 'votes', id);
    ga4EventSend(id, 'delete');
    await updateDoc(docRef, {
      likeCount: increment(-1),
    })
      .then(() => {
        getLikeCountData();
      })
      .catch((error) => {
        console.error('Error updating document: ', error);
      });
  };

  const ga4EventSend = (id: string, action: string) => {
    let selectedArea = allArea.find((area) => area.id > id);
    if (selectedArea && typeof window.gtag !== 'undefined') {
      window.gtag('event', 'click', {
        action:
          action === 'delete'
            ? `${selectedArea?.area ?? undefined} ${selectedArea?.floorPlan ?? undefined} 住みたい取り消し`
            : `${selectedArea?.area ?? undefined} ${selectedArea?.floorPlan ?? undefined} 住みたいクリック`,
      });
    }
  };
  const clickBtnLink = () => {
    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', 'click', {
        action: 'サポーター登録ボタンクリック(妄想マンション投票部分)',
      });
    }
    window.open(REGISTER_FORM_URL, '_blank');
  };

  const onChangeSliderIndex = (index: number) => {
    let planType;
    switch (index) {
      case 0:
      case 1:
        planType = TYPE_LIST[0];
        break;
      case 2:
      case 3:
        planType = TYPE_LIST[1];
        break;
      case 4:
      case 5:
        planType = TYPE_LIST[2];
        break;
      case 6:
      case 7:
        planType = TYPE_LIST[3];
        break;
      case 8:
      case 9:
        planType = TYPE_LIST[4];
        break;
      default:
        planType = TYPE_LIST[0];
    }
    console.log(planType);
    setSelectedType(planType);
  };
  useEffect(() => {
    getLikeCountData();
    filteringList(initailType);
    getOwnLikedList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div id="vote" className={s.areaFloorPlanRentWrapper}>
      <div className={s.side} ref={inViewRef2}>
        <FadeUp delay={0}>
          <ContentsTitle className={s.contentsTitle} title={`教えて！`} subTitle={`妄想マンション投票`} />

          <p className={s.lead}>
            <span className={s.block}>もしP-manがあったらどこに住みたい？</span>
            <span className={s.delay02}>場所は？</span>
            <span className={s.delay03}>賃料は？</span>

            <span className={classNames(s.block, s.delay04)}>あなたの声を参考にさせてください！</span>
          </p>

          <div className={classNames(s.leadContents, s.pcOnly)}>
            <h4>サポーター募集中！</h4>
            <p>実際に建築が決まった場合、「P-man」サポーターの方には優先情報の提供をさせていただきます。</p>
            <h4>さらに今なら！</h4>
            <p>
              ご登録いただいた方の中から、抽選で120名様にAmazonギフト券2,000円分をプレゼントします！
              サポーター登録をして皆さんの意見やアイディアをぜひお聞かせください。
            </p>
            <p className={s.note}>※物件によって優先情報をご案内できない場合がございます。</p>
            <a className={s.linkToForm} href={REGISTER_FORM_URL} target="_blank" rel="noreferrer">
              サポーター登録（無料）
              <GreenCircleArrow className={s.arrowIcon} />
            </a>
          </div>
        </FadeUp>
      </div>
      <div className={s.main}>
        <FadeUp>
          <div className={s.header}>
            <div className={s.scrollInner}>
              <ul>
                {TYPE_LIST.map((item, index) => (
                  <li
                    className={selectedType.type === item.type ? s.selected : ''}
                    key={index}
                    onClick={() => filteringList(item)}
                  >
                    {item.type}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className={classNames(s.contents)} ref={inViewRef1}>
            <div className={classNames(s.noteFlashMessage, s.spOnly)}>
              事業化検討中につき実際に物件が存在する訳ではございません。
              <br />
              写真はイメージです。
            </div>
            <AreaFloorPlanRentSlider
              slideToIndex={selectedType.slideStartIndex}
              floorPlanRentList={allAreaByType}
              ownLikedIdList={ownLikedIdList}
              likeCountList={likeCountList}
              onClickLikeButton={onClickLikeButton}
              onChangeSliderIndex={onChangeSliderIndex}
            />
          </div>
          <div className={classNames(s.leadContents, s.spOnly)}>
            <h4>サポーター募集中！</h4>
            <p>実際に建築が決まった場合、「P-man」サポーターの方には優先情報の提供をさせていただきます。</p>
            <h4>さらに今なら！</h4>
            <p>
              ご登録いただいた方の中から、抽選で120名様にAmazonギフト券2,000円分をプレゼントします！
              サポーター登録をして皆さんの意見やアイディアをぜひお聞かせください。
            </p>
            <p className={s.note}>※物件によって優先情報をご案内できない場合がございます。</p>

            <button className={s.linkToForm} onClick={clickBtnLink}>
              サポーター登録（無料）
              <GreenCircleArrow className={s.arrowIcon} />
            </button>
          </div>
        </FadeUp>
      </div>
      <ParallaxItem className={s.parallaxBg}>
        <img src={BgLogo} alt={SERVICE_NAME} width={381} height={500} />
      </ParallaxItem>
    </div>
  );
};
